<template>

    <div>

        <div class="breadcrumb justify-content-end">
            <div class="d-flex  breadcrumb-wrapper pr-1">
                <b-breadcrumb-item :to="{ name: 'dashboard' }"> <feather-icon    class="align-text-top" icon="HomeIcon" size="15"/></b-breadcrumb-item>
                <b-breadcrumb-item :to="{ name: 'setting_selects' }"> {{$t('label_client_settings')}}</b-breadcrumb-item>
                <b-breadcrumb-item active>{{parentOption.translation_index?$t(parentOption.translation_index) : ''}}</b-breadcrumb-item>
            </div>
        </div>

        <b-card no-body>

            <b-card-header class="pb-50">
                <h5>{{parentOption.translation_index?$t(parentOption.translation_index) : ''}}</h5>
            </b-card-header>

            <b-card-body>

                <validation-observer
                        #default="{ handleSubmit, invalid }"
                        :ref="PREFIX + '_FORM'"
                        tag="form"
                        class="p-2"
                >
                <!--*************************Dynamic BLOCK*********************************************************-->
                <dynamic-items-block
                        class="mt-2"
                        alias="procurators"
                        label="label_procurator"
                        @deleteItemServer="deleteItemFromServer($event)"
                        :show-items-section.sync="showItems"
                        :no-switch="true"
                        :blank-item="{name: '', color: '', id: 0}"
                        :items="addedOptions"
                        :new-items.sync="newOptions"
                >
                    <template #form="{item, ind, deleteItem}">
                        <button @click="deleteItem()"
                                :style="{top: ind>0? 'calc(50% - 8px)' : 'calc(50% - 20px)'}"
                                style=" position: absolute;;cursor:pointer; right: 0px;z-index: 10;"
                                class="remove-procurator-item btn  "
                                type="button">
                            <feather-icon color="red" title="Clear" data-toggle icon="XIcon" size="25"/>
                        </button>
                        <hr v-if="ind > 0" style="width:100%">

                        <b-col :cols="parentOption.id != 162? 12 : 8">

                            <validation-provider
                                    #default="validationProps"
                                    :name="$t('label_name')"
                                    slim
                                    rules="required"
                                    :vid="'opt_name_' + (item.id? '__' + item.id :  ind)"
                            >
                                <b-form-group
                                        :label="$t('label_name')"

                                        class="required mr-3"
                                >

                                    <b-form-input
                                            :placeholder="$t('label_name')"
                                            v-model="item.name"
                                            :state="getValidationState(validationProps)"
                                            trim
                                    />
                                    <b-form-invalid-feedback>
                                        {{ validationProps.errors[0] }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <b-col v-if="parentOption.id == 162" cols="4">

                            <validation-provider
                                    #default="validationProps"
                                    :name="$t('label_color')"
                                    slim
                                    rules="required"
                                    :vid="'opt_color_' + (item.id? '__' + item.id :  ind)"
                            >
                                <b-form-group
                                        :label="$t('label_color')"

                                        class="required mr-3"
                                >

                                    <b-form-input
                                            :placeholder="$t('label_color')"
                                            v-model="item.color"
                                            :state="getValidationState(validationProps)"
                                            trim
                                    />
                                    <b-form-invalid-feedback>
                                        {{ validationProps.errors[0] }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                    </template>
                    <template #button="{addBlankItem}">
                        <div class="mb-3">
                            <b-button @click.prevent="addBlankItem"
                                      class=" btn btn-success  ">
                                {{$t('label_new')}}
                            </b-button>
                        </div>
                    </template>
                </dynamic-items-block>

                    <div class="d-flex mt-2 justify-content-end">
                        <b-button @click.prevent="handleSubmit(onSubmit);focusOnFormError(invalid)"
                                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                  variant="primary"
                                  class="mr-2"
                                  type="submit"
                        >
                            {{ $t('label_save') }}
                        </b-button>
                        <b-button @click.prevent="$router.push({name: 'setting_selects', params:{id: parentOption.id}})"
                                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                                type="button"
                                variant="outline-secondary"

                        >{{ $t('label_cancel') }}</b-button>
                    </div>
                </validation-observer>
            </b-card-body>
        </b-card>

    </div>
</template>

<script>

    import {
        BCardText, BCard, BCardHeader, BCardBody,
        BTabs, BTab, BAlert, BButton, BPopover, BAvatar,
        BFormGroup,
        BFormInput, BFormInvalidFeedback,

    } from 'bootstrap-vue'

    import dynamicItemsBlock from '@src/views/components/dynamicItemsBlock'
    import {ValidationProvider, ValidationObserver} from 'vee-validate'

    export default {

        components: {

            BCardText,
            BCard, BCardHeader, BCardBody,
            BTabs, BTab, BAlert, BButton, BPopover, BAvatar,
            BFormGroup,
            BFormInput, BFormInvalidFeedback,
            dynamicItemsBlock,
            ValidationProvider, ValidationObserver
        },
        props:['selectOption'],

        data() {
            return {
                PREFIX:'client_setting',

                showItems:true,
                addedOptions: [],
                newOptions: [],

                parentOption: {},


                itemData: {}
            }

        },

        created() {

            this.getPageData();
        },

        methods:{

            getPageData(){
                let parent_id = this.$router.currentRoute.params.id;
                this.async('get', 'client_setting/' +  parent_id, {params:{}}, function(res){
                    this.parentOption = res.data;
                    this.addedOptions = res.data.options;
                    // this.tableItems = res.data.customer_types;
                });
            },

            deleteItemFromServer(item) {

                this.confirmDeleting((result) => {
                    this.async('delete','/' + this.PREFIX + '/option/' + item.id, {params: {}}, function (resp) {
                         this.getPageData();
                    });
                })
            },
            onSubmit() {
                let formData = new FormData();
                formData.append('parent_alias', this.parentOption.name_alias);
                formData.append('parent_id', this.parentOption.id);

                for(let i = 0; i< this.addedOptions.length; i++){
                    formData.append('name[]', this.addedOptions[i].name);
                    formData.append('color[]', this.addedOptions[i].color);
                    formData.append('option_id[]', this.addedOptions[i].id);
                }

               for(let i = 0; i< this.newOptions.length; i++){
                   formData.append('name[]', this.newOptions[i].name);
                   formData.append('color[]', this.newOptions[i].color);
                   formData.append('option_id[]', 0);
               }

                this.async('put', '/' + this.PREFIX + '/' + this.parentOption.id , formData, function (resp) {
                    this.getPageData();
                    this.$router.push({name:'setting_selects'});
                }, false, {headers:{'Content-Type': 'multipart/form-data'}});

            }

        }


    }
</script>

