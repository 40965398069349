var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"breadcrumb justify-content-end"},[_c('div',{staticClass:"d-flex  breadcrumb-wrapper pr-1"},[_c('b-breadcrumb-item',{attrs:{"to":{ name: 'dashboard' }}},[_c('feather-icon',{staticClass:"align-text-top",attrs:{"icon":"HomeIcon","size":"15"}})],1),_c('b-breadcrumb-item',{attrs:{"to":{ name: 'setting_selects' }}},[_vm._v(" "+_vm._s(_vm.$t('label_client_settings')))]),_c('b-breadcrumb-item',{attrs:{"active":""}},[_vm._v(_vm._s(_vm.parentOption.translation_index?_vm.$t(_vm.parentOption.translation_index) : ''))])],1)]),_c('b-card',{attrs:{"no-body":""}},[_c('b-card-header',{staticClass:"pb-50"},[_c('h5',[_vm._v(_vm._s(_vm.parentOption.translation_index?_vm.$t(_vm.parentOption.translation_index) : ''))])]),_c('b-card-body',[_c('validation-observer',{ref:_vm.PREFIX + '_FORM',staticClass:"p-2",attrs:{"tag":"form"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var invalid = ref.invalid;
return [_c('dynamic-items-block',{staticClass:"mt-2",attrs:{"alias":"procurators","label":"label_procurator","show-items-section":_vm.showItems,"no-switch":true,"blank-item":{name: '', color: '', id: 0},"items":_vm.addedOptions,"new-items":_vm.newOptions},on:{"deleteItemServer":function($event){return _vm.deleteItemFromServer($event)},"update:showItemsSection":function($event){_vm.showItems=$event},"update:show-items-section":function($event){_vm.showItems=$event},"update:newItems":function($event){_vm.newOptions=$event},"update:new-items":function($event){_vm.newOptions=$event}},scopedSlots:_vm._u([{key:"form",fn:function(ref){
var item = ref.item;
var ind = ref.ind;
var deleteItem = ref.deleteItem;
return [_c('button',{staticClass:"remove-procurator-item btn  ",staticStyle:{"position":"absolute","cursor":"pointer","right":"0px","z-index":"10"},style:({top: ind>0? 'calc(50% - 8px)' : 'calc(50% - 20px)'}),attrs:{"type":"button"},on:{"click":function($event){return deleteItem()}}},[_c('feather-icon',{attrs:{"color":"red","title":"Clear","data-toggle":"","icon":"XIcon","size":"25"}})],1),(ind > 0)?_c('hr',{staticStyle:{"width":"100%"}}):_vm._e(),_c('b-col',{attrs:{"cols":_vm.parentOption.id != 162? 12 : 8}},[_c('validation-provider',{attrs:{"name":_vm.$t('label_name'),"slim":"","rules":"required","vid":'opt_name_' + (item.id? '__' + item.id :  ind)},scopedSlots:_vm._u([{key:"default",fn:function(validationProps){return [_c('b-form-group',{staticClass:"required mr-3",attrs:{"label":_vm.$t('label_name')}},[_c('b-form-input',{attrs:{"placeholder":_vm.$t('label_name'),"state":_vm.getValidationState(validationProps),"trim":""},model:{value:(item.name),callback:function ($$v) {_vm.$set(item, "name", $$v)},expression:"item.name"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(validationProps.errors[0])+" ")])],1)]}}],null,true)})],1),(_vm.parentOption.id == 162)?_c('b-col',{attrs:{"cols":"4"}},[_c('validation-provider',{attrs:{"name":_vm.$t('label_color'),"slim":"","rules":"required","vid":'opt_color_' + (item.id? '__' + item.id :  ind)},scopedSlots:_vm._u([{key:"default",fn:function(validationProps){return [_c('b-form-group',{staticClass:"required mr-3",attrs:{"label":_vm.$t('label_color')}},[_c('b-form-input',{attrs:{"placeholder":_vm.$t('label_color'),"state":_vm.getValidationState(validationProps),"trim":""},model:{value:(item.color),callback:function ($$v) {_vm.$set(item, "color", $$v)},expression:"item.color"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(validationProps.errors[0])+" ")])],1)]}}],null,true)})],1):_vm._e()]}},{key:"button",fn:function(ref){
var addBlankItem = ref.addBlankItem;
return [_c('div',{staticClass:"mb-3"},[_c('b-button',{staticClass:" btn btn-success  ",on:{"click":function($event){$event.preventDefault();return addBlankItem($event)}}},[_vm._v(" "+_vm._s(_vm.$t('label_new'))+" ")])],1)]}}],null,true)}),_c('div',{staticClass:"d-flex mt-2 justify-content-end"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-2",attrs:{"variant":"primary","type":"submit"},on:{"click":function($event){$event.preventDefault();handleSubmit(_vm.onSubmit);_vm.focusOnFormError(invalid)}}},[_vm._v(" "+_vm._s(_vm.$t('label_save'))+" ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"button","variant":"outline-secondary"},on:{"click":function($event){$event.preventDefault();return _vm.$router.push({name: 'setting_selects', params:{id: _vm.parentOption.id}})}}},[_vm._v(_vm._s(_vm.$t('label_cancel')))])],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }